import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-tailwindcss-select';
import Edit from '../../static/stylus.png';
import Delete from '../../static/delete.png';
import XLS from '../../static/fileB.png'
import SeminarLogo from '../../static/seminar.png';
import { useSelector } from 'react-redux';
import { getAttendence } from '../../api/attendenceApi';
import { saveAs } from 'file-saver';
import {utils, write} from 'xlsx';
import DeleteDialog from '../modals/DeleteDialog';
import { deleteSeminar } from '../../api/seminarApi';

const SeminarHeader = ({seminar, addLocationFilter}) => {
  const user = useSelector(state => state.user);
  const [deleteSeminarModal, setDeleteSeminarModal] = useState({
    show:false,
    seminarId: null
  });
  const navigate = useNavigate();
  const getExcel = async () => {
    try {
      const data = await getAttendence(user.token,{seminarId: seminar.seminarId ? seminar.seminarId : seminar._id});

      const worksheet = utils.json_to_sheet(Object.values(data));
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
      saveAs(blob, `export.xlsx`);
      
    } catch (err) {
      console.log(err);
    }
  }
  const [location, setLocation] = useState({value: "Pick...", label:"בחר מיקום..."})
  return (
    <div dir='rtl' className='flex flex-col w-full justify-center items-center'>
      {deleteSeminarModal.show && deleteSeminarModal?.seminarId && <DeleteDialog setShowModal={setDeleteSeminarModal} text={"האם את/ה בטוח/ה שאתה רוצה למחוק את הסמינר"} deleteFunc={async () => {
        try {
          await deleteSeminar(user.token, deleteSeminarModal.seminarId);
          setDeleteSeminarModal(false);
          navigate('/');
        } catch (err) {
          console.log(err);
        }
      }}/>}
       <div className='w-full bg-white px-2 py-4 flex flex-col justify-center items-center border-b-2 border-gray-400'>
        <div className='w-full flex flex-row gap-2 justify-center items-center'>
          <div className='flex items-center'>
            <img
              src={SeminarLogo}
              className='w-[25px]'
            />
            <h2 className='font-bold text-lg gap-2 mx-2'>שם סמינר: {seminar.seminarName}</h2>
          </div>
            <Link to={`/editSeminar/${seminar._id}`}>
              <img src={Edit}/>
            </Link>
            <img 
              src={Delete}
              className='cursor-pointer'
              onClick={() => {
                setDeleteSeminarModal({show:true, seminarId: seminar._id})
              }}
            />
            <img src={XLS} className='w-8 h-8 cursor-pointer'  onClick={getExcel}/>           
        </div>
      </div>
      <div className='w-full bg-white px-2 py-4 flex flex-col justify-center items-center border-gray-400'>
        <div className='w-full flex flex-col gap-2 justify-start items-start'>
          <h2 className='font-bold text-lg gap-2 mx-2'> תחילת סמינר:  {new Date(seminar.startDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"}).split(",")[0]}</h2>    
        </div>
      </div>
      <div className='w-full bg-white px-2 py-4 flex flex-col justify-center items-center border-b-2 border-gray-400'>
        <div className='w-full flex flex-col gap-2 justify-start items-start'>
          <h2 className='font-bold text-lg gap-2 mx-2'> תחילת סוף: {new Date(seminar.endDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"}).split(",")[0]}</h2>    
        </div>
      </div>
      
      <div className='flex flex-col p-5'>
        <Link to={`/createCourse/${seminar._id}`}>
          <button className='p-5 bg-blue-300 rounded-md shadow-md hover:bg-blue-400 active:bg-blue-500'>
            <h2 className='text-white font-bold'>הוסף הרצאה</h2>
          </button>
        </Link>
      </div>
      <div className='relative w-[100] bg-white flex flex-row justify-center items-center gap-5 py-5'>
        <h2 className='text-black font-bold w-full ml-5'>סינון לפי מיקום:</h2>
        {seminar.locations && seminar.locations.length > 0 && <Select
          options={seminar.locations.map(location => ({label: location, value: location}))}
          value={location}
          onChange={(selection) => {
            addLocationFilter(selection.value);
            setLocation({value: selection.value, label:selection.value});
          }}
          classNames={{
            menu:"absolute w-[350px] h-[250px]  bg-white border-2 border-black overflow-scroll z-10",
            menuButton:() => "flex flex-row w-[350px] bg-white border-2 border-black px-2 justify-between z-10",
            listItem: ({ isSelected }) => (
              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                  isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
              }`
            )
          }}
        />}
      </div>
    </div>
  );
}

export default SeminarHeader