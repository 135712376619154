import React, { useState } from 'react'
import { QRCode } from 'react-qrcode-logo';
import deleteIcon from '../../static/delete_black.png';
import PlusIcon from '../../static/add.png';
import { sendZkongBind } from '../../api/zkongApi';
import { useSelector } from 'react-redux';

const StaticQRModal = ({courseId, type, closeModal}) => {
  const [eslBarcodes, setEslBarcodes] = useState([]);
  const [eslBarcodeToAdd, setEslBarcodeToAdd] = useState("");
  const [submitDone, setSubmitDone] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const user = useSelector(state => state.user);

  const addEslToList = () => {
    setEslBarcodes(eslBarcodes => {
      const newEslBarcodes = [...eslBarcodes]; 
      newEslBarcodes.push(eslBarcodeToAdd);
      return newEslBarcodes;
    });
    setEslBarcodeToAdd("");
  }

  const removeEslFromList = (barcodeToFilter) => {
    setEslBarcodes(eslBarcodes => {
      const newEslBarcodes = eslBarcodes.filter(barcode => barcode !== barcodeToFilter)
      return newEslBarcodes;
    })
  }

  const submitZkongData = async () => {
    if(!eslBarcodes || eslBarcodes.length <= 0) {
      return;
    }
    try {
      await sendZkongBind(
        user.token,
        {
          eslBarcodes: eslBarcodes,
          courseId: courseId,
          type: type
        }
      );
      setSubmitDone(true);
    } catch (err) {
      console.log(err)
      setSubmitError(true);
    } finally {
      setTimeout(() => {
        closeModal()
      }, 3000)
    }
  }

  return (
    <div>
      <div className='modal'>
        <div 
          className='relative w-auto my-6 mx-auto max-w-3xl overflow-scroll mt-[50px]'
        >
          {!submitDone && !submitError && <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-center items-center overflow-scroll z-40">
            <div className="relative p-3 flex flex-col items-center max-h-[50%]">
              {type === "start" && <QRCode size={250} value={`${window.location.origin}/attendencestart/${courseId}`}/>}
              {type === "end" && <QRCode size={250} value={`${window.location.origin}/attendenceend/${courseId}`}/>}
            </div>
            {eslBarcodes && eslBarcodes.length > 0  && <div className='flex flex-col justify-center gap-2 items-center pb-5'>
              <h2 className='text-black font-bold text-2xl mt-2'>טאגים</h2>
              {eslBarcodes.map((barcode, index) => <div className='p-2 w-full flex justify-between items-center border-gray-400 border-2 bg-gray-100 rounded-md' key={index}>
                <h2 className='text-black font-bold text-lg'>{barcode}</h2>
                <img src={deleteIcon} onClick={() => removeEslFromList(barcode)} className='hover:cursor-pointer'/>
              </div>)}
              <button 
                className='bg-blue-400 text-white font-bold rounded-lg
                  shadow-md hover:bg-blue-500 active:bg-blue-600 p-2 disabled:opacity-40'
                onClick={submitZkongData}
                disabled={!eslBarcodes || eslBarcodes.length <= 0}
              >
                שיוך טאגים
              </button>
            </div>}

            {/*
              COMMENTED OUT FEATURE TO BIND TO ZKONG.
   
              NOT TESTED. 
            
            */}
            {/* <div className='flex flex-col justify-center items-center mb-5'>
              <label
                className="flex flex-row justify-center text-lg font-bold text-gray-800"
              >
                הוספת טאג לשיוך
              </label>
              <div className="relative flex items-center">
                <img src={PlusIcon} className="absolute hover:cursor-pointer" width={25} onClick={addEslToList}/>
                <input
                  dir='rtl'
                  type="text"
                  name="seminarName"
                  value={eslBarcodeToAdd}
                  onChange={(e) => setEslBarcodeToAdd(e.target.value)}
                  className="block w-full h-10 pl-8 text-black 
                    bg-white border-b-2 border-black focus:ring-none outline-none"
                />
              </div>
            </div> */}
            <button className='bg-red-400 text-white font-bold rounded-lg shadow-md hover:bg-red-500 active:bg-red-600 p-2' onClick={closeModal}>
              סגור
            </button>
          </div>}
          {submitDone && <div 
            className=' border-2 rounded-lg shadow-lg relative flex flex-col w-full
            bg-white outline-none focus:outline-none p-9 justify-center 
            items-center overflow-scroll z-40'
          >
            <h2 className='text-black font-bold text-xl'> שיוך בוצע בהצלחה</h2>
          </div>}
          {submitError && <div 
            className=' border-2 rounded-lg shadow-lg relative flex flex-col w-full
            bg-white outline-none focus:outline-none p-9 justify-center 
            items-center overflow-scroll z-40'
          >
            <h2 className='text-red-600 font-bold text-xl'> שגיע בביצוע שיוך</h2>
          </div>}
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

    </div>
  )
}

export default StaticQRModal