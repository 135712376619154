import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CoruseItem from '../components/CoruseItem';
import { getAllCourses } from '../api/courseApi';
import Sidebar from '../components/layout/Sidebar';
import { deleteSeminar, getAllSeminars, getSyncing, syncSeminars } from '../api/seminarApi';
import SeminarItem from '../components/seminar/SeminarItem';
import DeleteDialog from '../components/modals/DeleteDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import OperationSuccesfulModal from '../components/modals/SuccessfulSync';
import OperationUnSuccesfulModal from '../components/modals/OperationUnSuccesfulModal';

const SeminarsPage = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [seminars, setSeminars] = useState([]);
  const [pagination, setpagination] = useState({
    loadMore:true,
    limit: 6,
    searchTerm:""
  });
  const [updating, setUpdating] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState({
    success: true,
    showModal: false,
    text:""
  })
  const [deleteSeminarModal, setDeleteSeminarModal] = useState({
    show: false,
    seminarId: null
  });
  
  const fetchData = async (limit, searchTerm) => {
    try {
      const res = await getAllSeminars(user.token, limit, searchTerm);
      setSeminars(res.seminars);
      setpagination(pg => ({...pg, loadMore: res.hasMore}));
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if(!user.token) {
      navigate('/login')
    }
    else {
      if(!deleteSeminarModal.show) {
        fetchData(pagination.limit, pagination.searchTerm);
      }
    }
  }, [deleteSeminarModal])
  
  const syncSeminarsFunc = async () => {
    try {
      setUpdating(true);
      await syncSeminars(user.token);
      setShowSyncModal({
        showModal:true,
        success: true,
        text:"!שוגר סינכרון"
      });
      setTimeout(() => {
        setShowSyncModal({
          showModal:false,
          success: true,
          text:""
        });
      }, 3000)
    } catch (err) {
      setShowSyncModal({
        showModal:true,
        success: false,
        text:(() => {
          if(err.response.data.msg == "Syncing already in progress") {
            return "הסינכרון הקודם עדיין לא הסתיים"
          }
          return "שגיאה לא יודע - הנה נסו מאוחר יותר"
        })()
      });
      setTimeout(() => {
        setShowSyncModal({
          showModal:false,
          success: true,
          text:""
        });
      }, 3000)
      console.log(err)
    }
  }


  useEffect(() => {
    if(user.token) {
      getSyncing(user.token).then(res => setUpdating(res.syncing));
      const intervalId = setInterval(async () => {
        const res = await getSyncing(user.token);
        if(updating && !res.syncing) {
          fetchData(pagination.limit, pagination.searchTerm);  
        }
        setUpdating(res.syncing);
      }, 5000)
      return () => {
        clearInterval(intervalId);
      }
    }
  }, []);
  return (
    <div className="flex flex-row justify-start">
      {showSyncModal.showModal && showSyncModal.success && <OperationSuccesfulModal text={showSyncModal.text} setShowModal={setShowSyncModal}/>}
      {showSyncModal.showModal && !showSyncModal.success && <OperationUnSuccesfulModal text={showSyncModal.text} setShowModal={setShowSyncModal}/>}
      {deleteSeminarModal.show && deleteSeminarModal?.seminarId && <DeleteDialog setShowModal={setDeleteSeminarModal} text={"האם את/ה בטוח/ה שאתה רוצה למחוק את הסמינר"} deleteFunc={async () => {
        try {
          //This is fucking abysmal
          //Okay, okay, it's slightly less abysmal now... Still shit though
          await deleteSeminar(user.token, deleteSeminarModal.seminarId);
          setDeleteSeminarModal(false);
        } catch (err) {
          console.log(err);
        }
      }}/>}
      <div dir={"ltr"} className='h-screen w-full bg-white flex flex-col items-center overflow-scroll gap-4 p-2'>
        <div className='w-full justify-center flex items-end gap-5 border-b-2 pb-4'>
          <div className=' flex w-full justify-center  items-end gap-2'>
            <div className='flex flex-col items-center w-[20%]'>
              <h2 className='text-3xl font-bold'>חיפוש</h2>
              <input
                className='rounded-lg border-2 p-2 w-full'
                value={pagination.searchTerm}
                onChange={(e) => {setpagination(pg => ({...pg, searchTerm:e.target.value}))}}
              />
            </div>
            <div className='flex flex-col items-center '>
              <button 
                className='p-2 bg-blue-400 text-white font-bold rounded-md shadow-md'
                onClick={() => {fetchData(pagination.limit, pagination.searchTerm)}}
              >
                חפש
              </button>

            </div>
          </div>
          <div className='w-[10%] flex justify-center items-end gap-2'>
            <Link to="/addSeminar">
              <button className='p-1 bg-blue-400 text-white font-bold rounded-md shadow-md'>
                הוסף סמינר
              </button>
            </Link>
            <button
              disabled={updating}
              className='p-1 bg-blue-400 text-white font-bold rounded-md shadow-md disabled:opacity-40'
              onClick={() => {
                syncSeminarsFunc();
              }}
            >
              סינכרון סמינרים
            </button>
          </div>
        </div>
          {seminars && seminars.length > 0 && 
            <div
              id='scrollable'
              className='bg-white p-2 flex flex-col 
              w-full justify-start gap-4 items-center  overflow-y-scroll overflow-x-clip'
            >
              <InfiniteScroll
                className='w-screen flex flex-col'
                dataLength={seminars.length}
                next={() => {
                  setpagination(pg => ({...pg, limit: pg.limit +5}))
                  fetchData(pagination.limit + 5);
                }}
                hasMore={pagination.loadMore}
                scrollableTarget={"scrollable"}
              >
                {seminars.map(seminar => <SeminarItem seminar={seminar} key={seminar._id} setDeleteSeminarModal={setDeleteSeminarModal}/>)}
              </InfiniteScroll>
          </div>
          }
      </div>
      <Sidebar/>

    </div>
  )
}

export default SeminarsPage