
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';
import Display from '../static/developer_mode_tv.png';
import AddImage from '../static/gallery-add.png';

import LocationIcon from '../static/loc.png';
import deleteIcon from '../static/delete_black.png';
import Datepicker from "react-tailwindcss-datepicker";
import { createSeminar, uploadSeimnarImage } from '../api/seminarApi';
import { getOrgLocations } from '../api/orgApi';
import Select from 'react-tailwindcss-select';
import LoadingModal from '../components/modals/LoadingModal';


const AddSeminarPage = () => {

  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seminar, setSeminar] = useState({
    seminarName:"",
    locations: [],
    startDate: "",
    startHour: 0,
    startMinute:0,
    endHour: 0,
    endMinute: 0,
    endDate: ""
  });
  const [error, setError] = useState("");
  console.log(locations);
  const onSubmit = async (e) => {
    e.preventDefault();
    if(!seminar.seminarName) {
      setError("חסר שם סמינר");
      return setTimeout(() => setError(""), 5000);
    }
    if(!seminar.locations || seminar.locations.length === 0) {
      setError("חסרים אזורים בהם מתקיים הסמינר");
      return setTimeout(() => setError(""), 5000); 
    }
    if(isNaN(seminar.startHour) || seminar.startHour < 0 || seminar.startHour > 23) {
      setError("שעת התחלת סמינר שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(seminar.endHour) || seminar.endHour < 0 || seminar.endHour > 23 ) {
      setError("שעת סיום סמינר שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(seminar.startMinute) || seminar.startMinute < 0 || seminar.startMinute > 59) {
      setError("דקת התחלת סמינר שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(seminar.endMinute) || seminar.endMinute < 0 || seminar.endMinute > 59) {
      setError("דקת סיום סמינר שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(!seminar.startDate) {
      setError("חסר תאריך תחלת סמינר");
      return setTimeout(() => setError(""), 5000);
    }
    if(!seminar.endDate) {
      setError("חסר תאריך סיום סמינר");
      return setTimeout(() => setError(""), 5000);
    }

    let startDate = new Date(seminar.startDate);
    let endDate = new Date(seminar.endDate);
    startDate.setHours(seminar.startHour, seminar.startMinute);
    endDate.setHours(seminar.endHour, seminar.endMinute);
    if(startDate.getTime() > endDate.getTime()) {
      setError("תאריך סיום סמינר אינו יכול להיות קודם יותר מהתחלה של הסמינר");
      return setTimeout(() => setError(""), 5000);
    }

    const seminarData = {...seminar};
    seminarData.startDate = startDate;
    seminarData.endDate = endDate;

    try {
      const res = await createSeminar(user.token, seminarData);
      console.log(res);
      navigate("/");
    } catch (err) {
      console.log(err)
    }

  }
  
  useEffect(() => {
    if(!user.token) {
      return navigate('/login')
    }
    getOrgLocations(user.token)
      .then(res => {
        setLocations(res.map(loc => ({label: loc, value: loc})))
        setLoading(false);
      })
      .catch(err => console.log(err))

  }, [user])

  const addLocationToList = (location) => {
    setSeminar(seminar => {
      const newSeminar = {...seminar}; 
      newSeminar.locations.push(location);
      console.log(newSeminar)
      return newSeminar;
    });
  }

  const imageUploadHandle = async (e) => {
    const file = e.target.files[0];
    if(file.type != "image/jpeg" && file.type != "image/png") {
      setError("תמונת חיבות להיות ב פורמט JPG או בפרומט PNG")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);

    try {
      setLoading(true);
      const result = await uploadSeimnarImage(user.token, form);
      setSeminar(seminar => ({...seminar, seminarPhoto:result.link}));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const removeLocationFromList = (locToFilter) => {
    setSeminar(seminar => {
      const newLocs = seminar.locations.filter(loc => loc !== locToFilter)
      console.log(newLocs)
      seminar.locations = newLocs;
      return {...seminar, locations:newLocs};
    })
  }

  return (
    <div  className='w-full flex flex-row justify-start items-start'>
      <div className="flex flex-col h-full w-full justify-center items-center">
        <div className='w-full flex justify-center items-center mt-5'>
          <h2 className='text-2xl text-black font-bold'>הוספת סמינר</h2>
        </div>
        {loading && <LoadingModal/>}
        {!loading  && <form className="mt-6 w-[50%]" onSubmit={onSubmit}>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-lg font-bold text-gray-800"
            >
              שם סמינר
            </label>
            <div className="relative flex items-center">
              
              <input
                dir='rtl'
                type="text"
                name="seminarName"
                onChange={(e) => setSeminar(seminar => ({...seminar, seminarName: e.target.value}))}
                className="block w-full h-10 pl-8 text-black 
                  bg-white border-b-2 border-black focus:ring-none outline-none"
              />
            </div>
          </div>
          <div className="relative flex flex-col justify-center items-center text-center w-full gap-2">
              <input id="imageInput" hidden type="file" accept='image/*' onChange={imageUploadHandle} />
              <label
                className='w-15 h-5 absolute top-[50px] left-0  cursor-pointer'
                htmlFor="imageInput"
                
              >
                <img htmlFor="imageInput"  src={AddImage} width={22}/>
              </label>
              <p
                className="block text-lg text-gray-800 font-bold"
              >
                תמונת סמינר
              </p>
              <input
                onChange={(e) => setSeminar(seminar => ({...seminar, seminarPhoto:e.target.value}))}
                type="text"
                name="imageLink"
                value={seminar.seminarPhoto}
                className="h-10 pl-8 text-black  border-b-[2px] border-[#000000]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
          {seminar.locations && seminar.locations.length > 0  && <div className='flex flex-col justify-center gap-2 items-center pb-5'>
            <h2 className='text-black font-bold text-2xl mt-2'>מיקומים</h2>
            {seminar.locations.map((loc, index) => <div className='p-2 w-[75%] flex justify-between items-center border-gray-400 border-2 bg-gray-100 rounded-md' key={index}>
              <h2 className='text-black font-bold text-lg'>{loc}</h2>
              <img src={deleteIcon} onClick={() => removeLocationFromList(loc)} className='hover:cursor-pointer'/>
            </div>)}
          </div>}
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              הוסף מיקום
            </label>
            <div className="relative flex items-center">
              <Select
                options={locations}
                value={{value:"בחר מיקום", label:"...בחר מיקום"}}
                onChange={(selection)=> {
                  if(!seminar.locations.includes(selection.value)) {
                    addLocationToList(selection.value)
                  }
                }}
                classNames={{
                  menu:"w-full absolute w-[100%] bg-white border-2 border-gray overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[100%]  bg-white border-2 border-gray px-2 justify-between z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
          </div>
          <div className='w-full flex flex-col justify-center items-center '>
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              תחילת סמינר
            </label>
            <div className='flex justify-between items-center w-full'>
              <div className='w-[50%]'>
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={(() => {return {startDate: seminar.startDate, endDate: seminar.startDate}})()}
                  onChange={(value) => {setSeminar(seminar => ({...seminar, startDate: value.startDate}))}}
                  displayFormat='DD-MM-YYYY'
                  inputClassName="text-black font-bold w-full opacity-100  p-5"
                />
              </div>
              <div className='w-[50%] flex justify-between items-center p-5 gap-2'>
                <input
                  onChange={(e) => setSeminar(seminar => ({...seminar, startHour: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black  rounded-md font-bold pl-[10%]'
                  max={24}
                />
                <h2 className='text-black font-bold'>: שעה</h2>
                <input
                  onChange={(e) => setSeminar(seminar => ({...seminar, startMinute: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: דקה</h2>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-col justify-center items-center '>
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              סוף סמינר
            </label>
            <div className='flex justify-between items-center w-full'>
              <div className='w-[50%]'>
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={(() => {return {startDate: seminar.endDate, endDate: seminar.endDate}})()}
                  onChange={(value) => {setSeminar(seminar => ({...seminar, endDate: value.startDate}))}}
                  displayFormat='DD-MM-YYYY'
                  inputClassName="text-black font-bold w-full opacity-100  p-5"
                />
              </div>
              <div className='w-[50%] flex justify-between items-center p-5 gap-2'>
                <input
                  onChange={(e) => setSeminar(seminar => ({...seminar, endHour: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: שעה</h2>
                <input
                  onChange={(e) => setSeminar(seminar => ({...seminar, endMinute: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: דקה</h2>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center p-5'>
            <button className='bg-blue-400 text-white font-bold text-xl p-3 rounded-lg shadow-md' type='submit'>
              שמור
            </button>
          </div>
          <div className='flex justify-center items-center p-5'>
            {error && <p className='text-red-400 font-bold text-xl'>{error}</p>}
          </div>
        </form>}
      </div>
      <Sidebar/>

    </div>
  )
}

export default AddSeminarPage