import React from 'react'

const DeleteDialog = ({setShowModal, deleteFunc, text}) => {
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex felx-col items-center justify-center p-4 rounded-t">
                <h3 className="text-md md:text-xl font-semibold text-gray-900 dark:text-white">
                    {text}
                </h3>
            </div>
            <div className="flex flex-row justify-center gap-10 p-10 w-[100%]">
              <button
                className='btn text-xl !p-3'
                onClick={() => deleteFunc()}
                >
                כן
              </button>
              <button
                className='btn text-xl !p-3'
                onClick={() => setShowModal(modalState => ({...modalState, show: false}))}
              >
                לא
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default DeleteDialog