import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSeminar } from '../api/seminarApi';
import { editCourse, getCourseById } from '../api/courseApi';
import Sidebar from '../components/layout/Sidebar';
import Datepicker from 'react-tailwindcss-datepicker';
import Select from 'react-tailwindcss-select';
import Display from '../static/developer_mode_tv.png';


const EditCoursePage = () => {
  const user = useSelector(state => state.user);
  const {courseId} = useParams();
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [seminar, setSeminar] = useState({});
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({
    courseId:"",
    location:"",
    courseName:"",
    startTime: "",
    date:"",
    startHour: 0,
    startMinute:0,
    endHour: 0,
    endMinute: 0,
    endTime: ""
  });
  const [error, setError] = useState("");
  
  const fetchData = async () => {
    try {
      const courseData = await getCourseById(courseId);
      setCourse({
        courseId: courseData.courseId,
        location: courseData.location,
        courseName: courseData.courseName,
        startTime:courseData.startTime,
        endTime: courseData.endTime,
        date: courseData.startTime.split("T")[0],
        startHour: parseInt(courseData.startTime.split("T")[1].split(":")[0]),
        endHour: parseInt(courseData.endTime.split("T")[1].split(":")[0]),
        startMinute:parseInt(courseData.startTime.split("T")[1].split(":")[1].split(":")[0]),
        endMinute: parseInt(courseData.endTime.split("T")[1].split(":")[1].split(":")[0]),
        seminarId: courseData.seminarId,
      });
      const seminarData = await getSeminar(user.token, courseData.seminarId);
      setSeminar(seminarData);
      setLocations(seminarData.locations.map(loc => ({label:loc, value:loc})));
      setLoading(false)
    } catch (err) {
      navigate('/');
      console.log(err);
    }
  }

  useEffect(() => {
    if(!user.token) {
      return navigate('/login');
    }
    fetchData();
    
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if(!course.courseName) {
      setError("חסר שם הרצאה");
      return setTimeout(() => setError(""), 5000);
    }
    if(!course.courseId) {
      setError("חסר מספר הרצאה");
      return setTimeout(() => setError(""), 5000);
    }
    if(!course.location) {
      setError("חסר מיקום ההרצאה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(course.startHour) || course.startHour < 0 && course.startHour < 23) {
      setError("שעת התחלה שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(course.endHour) || course.endHour < 0 && course.endHour < 23) {
      setError("שעת סיום שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(course.startMinute) || course.startMinute < 0 && course.startMinute > 59) {
      setError("דקת התחלה שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    if(isNaN(course.endMinute) || course.endMinute < 0 && course.endMinute > 59) {
      setError("דקת סיום שגויה");
      return setTimeout(() => setError(""), 5000);
    }
    let startDate = new Date(course.date);
    let endDate = new Date(course.date);
    startDate.setHours(course.startHour, course.startMinute);
    endDate.setHours(course.endHour, course.endMinute);

    let startDateSeminar = new Date(seminar.startDate);
    let endDateSeminar = new Date(seminar.endDate);
    if(
      startDateSeminar.getTime() > startDate.getTime()
      || endDateSeminar.getTime() < endDate.getTime()
    ) {
      setError("שעות ההרצאה לא יכולות להיות מחוץ לשעות הסמינר")
      return setTimeout(() => setError(""), 5000);
    }
    if(startDate.getTime() > endDate.getTime()) {
      setError("שעת הסיום לא יכולה להיות יותר קודמת משעת ההתחלה")
      return setTimeout(() => setError(""), 5000);
    }
    const courseData = {...course};
    courseData.startTime = startDate;
    courseData.endTime = endDate;
    courseData.seminarId = seminar.seminarId;
    try {
      const res = await editCourse(user.token, courseData);
      navigate(`/courses/${seminar._id}`)
      console.log(res);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="flex flex-row justify-start">
      <div className="flex flex-col h-full w-full justify-center items-center">
        <div className='w-full flex justify-center items-center mt-5'>
          <h2 className='text-2xl text-black font-bold'>הוספת הרצאה</h2>
        </div>
        <form className="mt-6 w-[50%]" onSubmit={onSubmit}>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              מספר הרצאה
            </label>
            <div className="relative flex items-center">
              <img src={Display} className="absolute" width={25}/>
              <input
                dir='rtl'
                type="text"
                name="courseId"
                value={course.courseId}
                onChange={(e) => setCourse(course => ({...course, courseId: e.target.value}))}
                className="block w-full h-10 pl-8 text-black 
                  bg-white border-b-2 border-black focus:ring-none outline-none"
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
              שם הרצאה
            </label>
            <div className="relative flex items-center">
              <img src={Display} className="absolute" width={25}/>
              <input
                dir='rtl'
                type="text"
                name="courseName"
                value={course.courseName}
                onChange={(e) => setCourse(course => ({...course, courseName: e.target.value}))}
                className="block w-full h-10 pl-8 text-black 
                  bg-white border-b-2 border-black focus:ring-none outline-none"
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800 mb-2"
            >
              בחירת מיקום
            </label>
            <div className="relative flex items-center">
              <Select
                options={locations}
                value={course.location ? {value:course.location, label:course.location}  : {value:"בחר מיקום", label:"...בחר מיקום"}}
                onChange={(selection)=> {
                  setCourse(course => ({...course, location: selection.value}));
                }}
                classNames={{
                  menu:"w-full absolute w-[100%] bg-white border-2 border-black rounded-md overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[100%]  bg-white border-2 rounded-md border-black px-2 justify-between z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            <div className='w-full flex flex-col justify-center items-center '>
            <label
              className="flex flex-row justify-center text-md font-semibold text-gray-800"
            >
             תאריך
            </label>
            <div className='flex justify-center items-center w-full'>
              <div className='w-[50%]'>
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={(() => {return {startDate: course.date, endDate: course.date}})()}
                  onChange={(value) => {setCourse(course => ({...course, date: value.startDate}))}}
                  displayFormat='DD-MM-YYYY'
                  inputClassName="text-black font-bold w-full opacity-100  p-5"
                />
              </div>
            </div>
            <div className='flex flex-col justify-center'>
              <label
                className="flex flex-row justify-center text-md font-bold text-gray-600"
              >
                שעת התחלה
              </label>
              <div className='w-[100%] flex justify-between items-center p-5 gap-2'>
                <input
                  value={course.startHour}
                  onChange={(e) => setCourse(course => ({...course, startHour: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black  rounded-md font-bold pl-[10%]'
                  max={24}
                />
                <h2 className='text-black font-bold'>: שעה</h2>
                <input
                  value={course.startMinute}
                  onChange={(e) => setCourse(course => ({...course, startMinute: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: דקה</h2>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-col justify-center items-center '>
            <label
              className="flex flex-row justify-center text-md font-bold text-gray-600"
            >
              שעת סיום
            </label>
            <div className='flex justify-center items-center w-full'>
              <div className='flex justify-between items-center p-5 gap-2'>
                <input
                  value={course.endHour}
                  onChange={(e) => setCourse(course => ({...course, endHour: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: שעה</h2>
                <input
                  value={course.endMinute}
                  onChange={(e) => setCourse(course => ({...course, endMinute: parseInt(e.target.value)}))}
                  className='w-[25%] border-b-2 border-black font-bold rounded-md pl-[10%]'
                />
                <h2 className='text-black font-bold'>: דקה</h2>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center p-5'>
            <button className='bg-blue-400 text-white font-bold text-xl p-3 rounded-lg shadow-md hover:bg-blue-500 active:bg-blue-600' type='submit'>
              שמור
            </button>
          </div>
          <div className='flex justify-center items-center p-5'>
            {error && <p className='text-red-400 font-bold text-xl'>{error}</p>}
          </div>
          </div>
        </form>
      </div>
      <Sidebar/>
    </div>
  )
}

export default EditCoursePage