
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutIcon from '../../static/logout.png';
import AuditIcon from '../../static/audit.png';
import SeminarIcon from '../../static/seminar.png';
const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const Logout = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  }
  return (
    <div className="border-r-2 h-full hidden md:flex" dir='rtl'>
    <div className="flex flex-col p-3 bg-white  w-20 md:w-80 h-screen border">
      <div className="flex flex-col justify-center items-center space-y-3 mt-5">
        <div className="flex flex-row items-center justify-center space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
          <h2 className="text-lg md:text-[16px] text-gray-700 font-extrabold uppercase">Smart Attendence System</h2>
        </div>
        <div className="flex flex-col justify-center items-start text-gray-700">
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start ">
            <li className="rounded-sm">
              <Link
                to="/"
                className="flex items-center p-2 space-x-3 rounded-md gap-2"
              >
                <img src={SeminarIcon} width={30} alt=''/>
                <span className="hidden md:block font-bold">סמינרים</span>
              </Link>
            </li>
          </ul>
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start">
            <li className="rounded-sm">
              <Link
                to="/trailLog"
                className="flex items-center p-2 space-x-3 rounded-md gap-2"
              >
                <img src={AuditIcon} width={30} alt=''/>
                <span className="hidden md:block font-bold">רשימת פעולות אחרונות</span>
              </Link>
            </li>
          </ul>
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start">
            <li className='flex items-center p-2 space-x-3 rounded-md cursor-pointer gap-2' onClick={Logout}>
              <img src={LogoutIcon} width={30} alt=''/>
              <span className="hidden md:block font-bold">יציאה</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Sidebar