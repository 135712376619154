import React from 'react'
import { Link } from 'react-router-dom'
import Delete from '../static/delete.png'
import Edit from '../static/stylus.png'
import QrCode from '../static/qr-code.png';
import Download from '../static/download.png';
import LectureIcon from '../static/conference.png';
const CoruseItem = ({courseId,courseName, location, setStaticQRCodeModal, courseInnerId, onDelete, startTime, endTime}) => {
  return (
    <div
      dir="rtl"
      className='flex flex-col w-[100%] min-h-[150px] justify-start items-center text-center rounded-xl  gap-2 border-[1px] border-gray-200'
    >
      <div className='flex justify-between items-center gap-2 bg-[#F7F7F8] w-full p-2 rounded-t-md'>
        <div className='flex  gap-2 justify-center overflow-x-scroll p-2'>
          <img
            src={LectureIcon}
            className='w-[25px]'
          />
          <h2 className='font-bold text-md xl:text-xl'>{courseId}</h2>
        </div>
        <div className='flex justify-center gap-5'>
          <Link to={`/editCourse/${courseInnerId}`}>
            <button 
              className=''
            >
              <img className='w-[25px]' src={Edit} />
            </button>
          </Link>
          <Link>
            <button 
              className=''
            >
              <img className='w-[25px]' src={Delete} onClick={onDelete}/>
            </button>
          </Link>
        </div>
      </div> 
      <div className='w-[50%] flex flex-col items-start justify-center gap-2'>
        <h2 className='text-black font-bold text-2xl'>{courseName}</h2>
        <h2 className='text-gray-800 font-bold text-lg'>מיקום הרצאה: {location}</h2>
        <h2 className='text-gray-800 font-bold text-lg'>תאריך: {startTime.split("T")[0]}</h2>
        <h2 className='text-gray-800 font-bold text-lg'>שעת התחלה: {startTime.split("T")[1].split("Z")[0].split(".")[0].substring(0,5)}</h2>
        <h2 className='text-gray-800 font-bold text-lg'>שעת סיום: {endTime.split("T")[1].split("Z")[0].split(".")[0].substring(0,5)}</h2>
      </div>
      <div className='flex flex-col justify-center items-center mt-5 border-4 rounded-lg'>
        <h2 className='text-xl font-bold text-gray-800'>קוד דינאמי</h2>
        <div className='flex justify-center items-center p-5 gap-2'>
          <Link to={`/getQrCode/${courseInnerId}/${"start"}`}>
            <button className='flex  gap-2 bg-blue-300 rounded-lg shadow-md text-black p-3 font-bold'>
              <img src={QrCode} className='w-6'/>
              כניסה
            </button>
          </Link>
          <Link to={`/getQrCode/${courseInnerId}/${"end"}`}>
            <button className='flex  gap-2 bg-blue-300 rounded-lg shadow-md text-black p-3 font-bold'>
              <img src={QrCode} className='w-6'/>
              יציאה
            </button>
          </Link>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center mt-5 border-4 rounded-lg'>
        <h2 className='text-xl font-bold text-gray-800'>קוד סטטי</h2>
        <div className='flex justify-center items-center p-5 gap-2'>
          <button 
            className='flex  gap-2 bg-blue-300 rounded-lg shadow-md text-black p-3 font-bold'
            onClick={() => setStaticQRCodeModal(courseInnerId,"start")}
          >
            <img src={Download} className='w-6'/>
            כניסה
          </button>
          <button 
            className='flex  gap-2 bg-blue-300 rounded-lg shadow-md text-black p-3 font-bold'
            onClick={() => setStaticQRCodeModal(courseInnerId,"end")}
          >
            <img src={Download} className='w-6'/>
            יציאה
          </button>
        </div>
      </div>

    </div>
  )
}

export default CoruseItem