import React from 'react'
import { Link } from 'react-router-dom'
import ArrowLeft from '../../static/arrow-left.png';
import RemoveIcon from '../../static/delete.png';
import SeminarIcon from '../../static/seminar.png';
const SeminarItem = ({seminar, setDeleteSeminarModal}) => {
  return (
    <Link to={seminar.seminarId ? `/courses/${seminar.seminarId}` :`/courses/${seminar._id}`}  dir={"rtl"} className='relative flex flex-row justify-end items-start bg-white p-4 gap-3 border-b-2 border-gray-200 w-[90%]'>
    <>
      <div className='flex flex-col justify-start items-start gap-2 w-full '>
        <div className='w-full flex items-center gap-2'>
          <img
            src={SeminarIcon}
            className='w-[25px]'
          />
          <h2 className='text-black font-bold text-2xl'> {seminar.seminarName}</h2>
          {seminar.seminarId && <h2 className='text-black font-bold text-2xl'> ({seminar.seminarId})</h2>}

        </div>
        <h2 className='text-gray-500 font-semibold text-xl'>
           תחילת סמינר:  {new Date(seminar.startDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"}).split(",")[0]}
        </h2>
        <h2 className='text-gray-500 font-semibold text-xl'>
          תחילת סוף: {new Date(seminar.endDate).toLocaleString("en-GB", {timezone:" Asia/Jerusalem"}).split(",")[0]}
        </h2>
        <div className='w-full flex items-center justify-start'>
          <img
            src={RemoveIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDeleteSeminarModal({
                show:true,
                seminarId: seminar._id
              })
            }}
          />
        </div>
      </div>
        <button className='flex flex-col justify-center items-center mt-10'>
          <img src={ArrowLeft} width={25}/>
        </button>
    </>
    </Link>
  )
}

export default SeminarItem