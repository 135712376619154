import React from 'react'
import OKIcon from '../../static/charger.png';
const OperationUnSuccesfulModal = ({setShowModal, text}) => {
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col items-center bg-white rounded-lg shadow dark:bg-gray-700 w-[80%]">
            <div className='w-full flex items-center justify-center p-2 gap-5'>
              <img
                src={OKIcon}
                className='w-10'
              />
              <h2 className='text-black font-bold text'>{text}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

    </div>
  )
}

export default OperationUnSuccesfulModal;