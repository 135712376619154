import axios from 'axios';

export const getCourse = async (id, location) => {
  const res = await axios.get(`https://seahorse-app-c3rmx.ondigitalocean.app/course/getCourse?courseId=${id}&location=${location}`);
  return res.data;  
}

export const getCourseById = async(id) => {
  const res = await axios.get(`https://seahorse-app-c3rmx.ondigitalocean.app/course/getCourseById?courseId=${id}`);
  return res.data;
}


export const createAttendence = async (data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/createAttendence`,
    data,
  );
  return res.data;
}
export const updateCourseStart = async (data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/updateAttendence/start`,
    data,
  );
  return res.data;
}

export const updateCourseEnd = async (data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/updateAttendence/end`,
    data
  );
  return res.data;
}

export const getAllCourses = async (token, seminarId, page, locationFilter) => {
  if(locationFilter) {
    locationFilter = locationFilter.replace("+", "%2B");
  }
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/getallcourses?seminarId=${seminarId}${page ? `&page=${page}` : ""}${locationFilter && `&locationFilter=${locationFilter}`}`,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const createQrLink = async (token, data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/createQrLink`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const createQrLinkById = async (token, data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/createQrLinkId`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getQRLink = async (qrLink) => {
  const res = await axios.get(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/getQrLink?qrLink=${qrLink}`
  );
  return res.data;
}

export const createCourse = async (token, data) => {
  const res = await axios.post(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  )
}

export const deleteCourse = async (token, data) => {
  const res = await axios.delete(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course`,
    {
      params:{
        courseId: data.courseId,
      },
      headers:{
        token: `bearer ${token}`
      }
    }
  )
}

export const editCourse = async (token, data) => {
  const res = await axios.put(
    `https://seahorse-app-c3rmx.ondigitalocean.app/course/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
}

